import React, { useState } from 'react';
import './Contact.scss';
import Navbar from '../../components/Navbar/Navbar';
import { Footer } from '../../components/Footer/Footer';
import emailjs from 'emailjs-com';
import ReCAPTCHA from 'react-google-recaptcha';

export default function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const [charCount, setCharCount] = useState(0);
  const [snackbar, setSnackbar] = useState({ show: false, message: '', type: '' });
  const [captchaToken, setCaptchaToken] = useState(null);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    if (e.target.name === 'message') {
      setCharCount(e.target.value.length);
    }
  };

  const handleCaptchaChange = (token) => {
    setCaptchaToken(token); 
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  
    if (!captchaToken) {
      setSnackbar({ show: true, message: 'Please complete the CAPTCHA.', type: 'error' });
      return;
    }

    const emailData = {
      from_name: formData.name, 
      message: formData.message,  
      from_email: formData.email, 
      'g-recaptcha-response': captchaToken,  
    };
  
    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        emailData, 
        process.env.REACT_APP_EMAILJS_USER_ID
      )
      .then(
        (result) => {
          setSnackbar({ show: true, message: 'Message sent successfully!', type: 'success' });
          console.log('Email sent successfully:', result.text);
        },
        (error) => {
          setSnackbar({ show: true, message: 'Error sending message. Please try again.', type: 'error' });
          console.error('Error sending email:', error.text);
        }
      );
  
    setFormData({
      name: '',
      email: '',
      message: '',
    });
    setCharCount(0);
    setCaptchaToken(null); 
    setTimeout(() => setSnackbar({ show: false, message: '', type: '' }), 5000);
  };

  return (
    <>
      <Navbar />

      <div className="contact-form-container">
      <h1>Contact Me</h1>
      <p>
      I'm available for contact by email <a href="mailto:mail@ashleyleal.ca">mail@ashleyleal.ca</a>,
      <br />
      but feel free to reach out to me through this form!
      </p>
        <form onSubmit={handleSubmit}>
          <label>
            Name:
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </label>
          <br />
          <label>
            Email:
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </label>
          <br />
          <label>
            Message:
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              maxLength="1000"
              required
            ></textarea>
            <div className="char-count">{charCount}/1000</div>
          </label>
          <br />

          <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} 
            onChange={handleCaptchaChange}
          />

          <button type="submit">Send</button>
        </form>

        {snackbar.show && (
          <div className={`snackbar ${snackbar.type}`}>
            {snackbar.message}
          </div>
        )}
      </div>
      <Footer />
    </>
  );
}
