import React, { useRef, useState, useEffect } from "react";
import "./HoverImage.scss";

export const HoverImage = ({ src, alt, hoverText }) => {
  const imgRef = useRef(null);
  const [hover, setHover] = useState(false);

  useEffect(() => {
    const img = imgRef.current;

    if (!img) return;

    const handleMouseMove = (e) => {
      const rect = img.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;

      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, img.width, img.height);

      const pixel = ctx.getImageData(x, y, 1, 1).data;
      const isTransparent = pixel[3] === 0;

      setHover(!isTransparent);
    };

    const handleMouseEnter = () => setHover(true);
    const handleMouseLeave = () => setHover(false);

    img.addEventListener("mousemove", handleMouseMove);
    img.addEventListener("mouseenter", handleMouseEnter);
    img.addEventListener("mouseleave", handleMouseLeave);

    return () => {
      img.removeEventListener("mousemove", handleMouseMove);
      img.removeEventListener("mouseenter", handleMouseEnter);
      img.removeEventListener("mouseleave", handleMouseLeave);
    };
  }, []);

  return (
    <div style={{ position: "relative", display: "inline-block" }}>
      <img
        ref={imgRef}
        src={src}
        alt={alt}
        style={{
          opacity: hover ? 0 : 1,
          transition: "opacity 0.3s ease-in-out",
        }}
      />
      {hover && (
        <div
          style={{
            position: "absolute",
            top: "220px",
            left: "200px",
            transform: "translate(-50%, -50%)",
            color: "#DBBFDD",
            fontSize: "13px",
            fontFamily: "Helvetica",
            fontWeight: "bold",
            pointerEvents: "none", 
          }}
        >
          {hoverText}
        </div>
      )}
    </div>
  );
};
