import './Resume.scss';
import Navbar from '../../components/Navbar/Navbar';
import { Footer } from '../../components/Footer/Footer';

export default function Resume() {
    return (
        <>
            <Navbar />
            <div className='filler'><p><br></br>This page is still in progress. Check back soon!</p></div>
            <Footer />
        </>
    );
}