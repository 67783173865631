import './Navbar.scss';
import desktopLogo from '../../assets/navbar-logo-desktop.svg';
import { NavLink } from "react-router-dom";

export default function Navbar() {
    return (
        <nav className="navbar">
            <div className="navbar-list">
                <div className="navbar-item">
                    <NavLink to="/#about" className="navbar-link">about</NavLink>
                </div>
                <div className="navbar-item">
                    <NavLink to="/projects" className="navbar-link">projects</NavLink>
                </div>
                <div className="navbar-item">
                    <NavLink to="/" className="navbar-img">
                        <img src={desktopLogo} alt="logo" />
                    </NavLink>
                </div>
                <div className="navbar-item">
                    <NavLink to="/resume" className="navbar-link">resume</NavLink>
                </div>
                <div className="navbar-item">
                    <NavLink to="/contact" className="navbar-link">contact</NavLink>
                </div>
            </div>
        </nav>
    );
}